import React, {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../store/store";

const SecureContext = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch<Dispatch>();

    const { accessToken } = useSelector((state: RootState) => state.user);

    // hack to toggle admin manually
    useEffect(() => {
        dispatch.user.toggleAdmin(true);
    }, []);

    useEffect(() => {
        if (null === accessToken && location.pathname !== "/sign-in") {
            navigate("/sign-in");
        }
    }, [accessToken, location.pathname, navigate]);

    return (
        <>
            { !!accessToken ? <Outlet /> : '' }
        </>
    );
};

export default SecureContext;