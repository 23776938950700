import React from 'react';
import loopstackLogo from '../../loopstack_logo2.png';
import {Box, Button, Grid, TextField, Typography, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, RootState} from "../../store/store";
import DoneIcon from '@mui/icons-material/Done';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {WaitlistInterface} from "../../store/waitlist";
import LoadingButton from '@mui/lab/LoadingButton';

function Home() {

    const navigate = useNavigate();
    const theme = useTheme();

    const { accessToken } = useSelector((state: RootState) => state.user);

    const [email, setEmail] = React.useState('');
    const dispatch = useDispatch<Dispatch>()
    const waitlist: WaitlistInterface = useSelector((state: RootState) => state.waitlist);

    const handleJoinWaitlist = () =>{
        dispatch.waitlist.joinWaitlist({ email });
    }

    const handleLogout = () => {
        dispatch.user.setToken(null);
    }

  return (
    <div className="App">

            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex'}}>
                        <Box>
                            <img src={loopstackLogo} className="App-logo" alt="logo" style={{ width: 250, height: 'auto' }}/>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <Box sx={{ p: 2 }}>
                            {accessToken ? <>
                                <Button sx={{ ml: 1 }} onClick={handleLogout}>Logout</Button>
                            </> : <>
                                {/*<Button sx={{ ml: 1 }} onClick={() => navigate(`/sign-up`)}>Sign Up</Button>*/}
                                <Button sx={{ ml: 1 }} onClick={() => navigate(`/sign-in`)} startIcon={<AccountCircle />}>Login</Button>
                            </>}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box sx={{ textAlign: 'left', fontSize: '1rem' }}>
                        <p style={{ fontSize: '1.8rem', textAlign: 'left'  }}>
                            We are building an autopilot for software engineering.
                        </p>
                        <p>
                            At loopstack we are working on new concepts of how humans and AI can work together to build better software.
                            With `autopilots` we are aiming to automate substantial parts of the software development process while keeping developers in full control.
                            We start with the idea, not with code.
                        </p>
                        {/*<p>*/}
                        {/*    We are creating an automation solution for all steps of the software development process from concept to code. Our solution is built for real-world developer workflows, ensuring full control at every step of the process.*/}
                        {/*</p>*/}
                        <p>
                            We are <Link href={'https://www.linkedin.com/in/jakob-klippel-414a30261/'} target='_blank'>Jakob</Link>, <Link href={'https://www.linkedin.com/in/tristan-kreuziger/'} target='_blank'>Tristan</Link>, and <Link href={'https://www.linkedin.com/in/kevin-hildebrandt/'} target='_blank'>Kevin</Link> and we are the co-founders of loopstack.ai.
                        </p>
                        <p>
                            If you want to join the team or support the project, contact us via team@loopstack.ai
                        </p>
                        <p>
                            Follow us on <Link href={'https://twitter.com/LoopstackAI'} target='_blank'>X</Link> or <Link href={'https://linkedin.com/company/loopstack-ai'} target='_blank'>LinkedIn</Link> and join our waitlist to stay up to date .
                        </p>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, mt: 6 }}>
                            {/*<ButtonGroup>*/}
                            <TextField variant="outlined" size={'small'} placeholder={'Your email'} sx={{ pr: 2 }} onChange={(evt) => setEmail(evt.target.value)} />
                            <LoadingButton
                                loading={waitlist?.loading}
                                variant={'contained'} sx={{ backgroundColor: '#B5E43C'}} onClick={handleJoinWaitlist}>Join the waitlist now</LoadingButton>


                            {/*</ButtonGroup>*/}
                        </Box>
                        {waitlist?.joined ? <Box sx={{ display: 'flex', alignItems: 'end', mb: 2 }}><DoneIcon sx={{ width: 15, mr: 2, color: theme.palette.success.main }} /><Typography variant={'caption'} color={theme.palette.success.main}>You joined the waitlist.</Typography></Box> : ''}
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ mt: 6, lineHeight: 0.5, color: theme.palette.grey['600'] }}>
                        <Typography variant={'caption'}>
                            &copy; Copyright {(new Date().getFullYear())}. Loopstack GmbH
                        </Typography>
                        <br />
                        <Typography variant={'caption'}>
                            Geschäftsführung: Jakob Klippel. Eingetragen beim Amtsgericht Hamburg, HRB 186171. Verantwortlicher für eigene Inhalte gem. § 55 RStV: Jakob Klippel
                        </Typography>
                        <br />
                        {/*<Typography variant={'caption'}>*/}
                        {/*    We are using no cookies*/}
                        {/*</Typography>*/}
                    </Box>
                </Grid>
            </Grid>
    </div>
  );
}

export default Home;
