import {init, Models, RematchDispatch, RematchRootState} from "@rematch/core";
import { user } from "./user";
import persistPlugin from "@rematch/persist";
import storage from "redux-persist/lib/storage";
import {waitlist} from "./waitlist";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ['user', 'waitlist'],
};

export interface RootModel extends Models<RootModel> {
    user: typeof user;
    waitlist: typeof waitlist;
}

export const models: RootModel = { user, waitlist };

export const store = init({
    // @ts-ignore
    plugins: [persistPlugin(persistConfig)],
    models
});

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;