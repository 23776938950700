import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import Home from "./pages/Home/Home";
import { Provider } from 'react-redux';
import {store} from "./store/store";
import {createTheme, CssBaseline, ThemeOptions, ThemeProvider} from "@mui/material";
import SecureContext from "./context/SecureContextProvider";
import Dashboard from "./pages/Dashboard/Dashboard";

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#B5E43C',
        },
        secondary: {
            main: '#B5E43C',
        },
        background: {
            default: '#000c43',
            paper: '#262f50',
        },
    },
};
const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <Home/>
        ),
        children: [
        ],
    },
    {
        path: "/sign-in",
        element: (
            <SignIn />
        ),
    },
    // {
    //     path: "/sign-up",
    //     element: (
    //         <SignUp />
    //     ),
    // },
    {
        path: "/secure",
        element: (
            <SecureContext />
        ),
        children: [
            {
                path: "dashboard",
                element: <Dashboard />,
                children: [],
            },
        ],
    }
]);

export const darkTheme = createTheme(themeOptions);

function App() {
  return (
      <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
      </ThemeProvider>
  );
}

export default App;
