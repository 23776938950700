import {createModel, RematchDispatch} from "@rematch/core";
import {RootModel} from "./store";
import axios from "axios";
import config from "../config/config";

export interface UserStateInterface {
    accessToken: string | null;
    isAdmin: boolean;
    loading: boolean;
    error: string | null;
}

const initialState: UserStateInterface = {
    accessToken: null,
    isAdmin: true,
    loading: false,
    error: null
}

export const user: any = createModel<RootModel>()({
    state: initialState,
    reducers: {
        toggleAdmin(state: UserStateInterface, isAdmin: boolean) {
            return {
                ...state,
                isAdmin
            };
        },
        setToken(state: UserStateInterface, token: string) {
            return {
                ...state,
                accessToken: token
            };
        },
        setError(state: UserStateInterface, payload: string | null) {
            return {
                ...state,
                error: payload
            };
        },
        setLoading(state: UserStateInterface, payload: boolean) {
            return {
                ...state,
                loading: payload
            };
        }
    },
    effects: (dispatch: RematchDispatch<RootModel>) => ({
        async signInUser({ username, password, rememberMe }) {
            dispatch.user.setError(null);
            dispatch.user.setLoading(true);
            try {
                const response = await axios.post(config.backendApi + '/api/login', { username, password, rememberMe });
                if (response.data) {
                    dispatch.user.setToken(response.data['access_token'] ?? null);
                }
            } catch (error: any) {
                if (error.response?.status === 401) {
                    dispatch.user.setError('Email or Password is incorrect.');
                } else {
                    dispatch.user.setError('Unknown error.');
                }
            }

            dispatch.user.setLoading(false);
        },
        // async signUpUser({ username, password }) {
        //     dispatch.user.setError(null);
        //     dispatch.user.setLoading(true);
        //     try {
        //         await axios.post(config.backendApi + '/auth/register', { username, password});
        //     } catch (error: any) {
        //         if (error.response.status === 409) {
        //             dispatch.user.setError('Email already in use.');
        //         } else {
        //             dispatch.user.setError('Unknown error.');
        //         }
        //     }
        //     dispatch.user.setLoading(false);
        // }
    }),
});