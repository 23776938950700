import {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

const RedirectSignedInUser = ({ children }: { children: any }) => {

    const navigate = useNavigate();
    const { accessToken } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (null !== accessToken) {
            navigate("/secure/dashboard");
        }
    }, [accessToken, navigate]);

    return <>
        { null === accessToken ? children : 'redirecting...' }
    </>;
};

export default RedirectSignedInUser;