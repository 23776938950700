import {createModel} from "@rematch/core";
import {Dispatch, RootModel} from "./store";
import config from "../config/config";
import axios from "axios";

export interface WaitlistInterface {
    loading: boolean;
    error: string | null;
    joined: boolean;
}

const initialState: WaitlistInterface = {
    loading: false,
    error: null,
    joined: false,
}

export const waitlist: any = createModel<RootModel>()({
    state: initialState,
    reducers: {
        setJoined(state: WaitlistInterface, isJoined: boolean) {
            return {
                ...state,
                joined: isJoined
            };
        },
        setLoading(state: WaitlistInterface, payload: boolean) {
            return {
                ...state,
                loading: payload
            };
        },
        setError(state: WaitlistInterface, payload: string | null) {
            return {
                ...state,
                error: payload
            };
        },
    },
    effects: (dispatch: Dispatch) => ({
        async joinWaitlist({ email }: { email: string }): Promise<void> {

            dispatch.waitlist.setLoading(true);
            dispatch.waitlist.setError(null);

            try {
                const response = await axios.post(config.backendApi + `/api/waitlist`, { email })
                if (response.data) {
                    dispatch.waitlist.setJoined(true);
                }
            } catch (e: any) {
                dispatch.waitlist.setError(e.message);
            }

            dispatch.waitlist.setLoading(false);
        },
    }),
});